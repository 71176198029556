import React, { useEffect, useState } from 'react';
import { ReviewForm } from '../../components/ReviewForm';
import { ReviewList } from '../../components/ReviewList';
import Layout from "../../components/Layout";
import axios from 'axios';
import { toast } from 'react-toastify';

const Review = () => {
    const [reviews, setReviews] = useState([]);

    const handleSubmitReview = (reviewData) => {
        const newReview = {
            ...reviewData,
            date: new Date().toLocaleDateString('bg-BG', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        };
        axios.post("https://viksitriksi-reviews-default-rtdb.europe-west1.firebasedatabase.app/reviews.json", newReview)
            .then((response) => {
                toast.success("Успешно изпратихте отзива!");
            })
            .catch((error) => {
                toast.error("Неусепшно изпращане!");
            });
        if (reviews != null) {
            setReviews([newReview, ...reviews]);
        } else {
            setReviews([newReview]);
        }

    };

    useEffect(() => {
        axios.get("https://viksitriksi-reviews-default-rtdb.europe-west1.firebasedatabase.app/reviews.json")
            .then((response) => {
                const fetchedData = [];
                for (let key in response.data) {
                    fetchedData.push(response.data[key]);
                }
                setReviews(fetchedData);
            })
    }, [])
    return (
        <Layout>
            <div className="min-h-screen bg-gray-100 p-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold text-gray-900 mb-8">Напишете отзив</h1>
                    <ReviewForm onSubmit={handleSubmitReview} />
                    <h2 className="text-2xl font-bold text-gray-900 mb-6">Чести отзиви</h2>
                    <ReviewList reviews={reviews} />
                </div>
            </div>
        </Layout>
    );
}

export default Review;