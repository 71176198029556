import React, { useState } from "react";
import Layout from "../../components/Layout";
import { ServiceCard } from "../../components/ServiceCard";
import { ServiceModal } from "../../components/ServiceModal";
import { servicesDataForPageService } from "../../data/services";

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);
    return (
        <Layout>
            <div className="min-h-screen bg-gray-50">
                <div className="container mx-auto px-4 py-12">
                    <h1 className="text-4xl font-bold text-center text-primary mb-2">Услуги</h1>
                    <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
                        Създавам незабравими моменти за вашите деца
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {servicesDataForPageService.map((service) => (
                            <ServiceCard
                                key={service.id}
                                {...service}
                                onOpen={() => setSelectedService(service)}
                            />
                        ))}
                    </div>
                </div>

                {selectedService && (
                    <ServiceModal
                        isOpen={!!selectedService}
                        onClose={() => setSelectedService(null)}
                        service={selectedService}
                    />
                )}
            </div>
        </Layout>
    );
};

export default Services;
