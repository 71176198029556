import dance from "../assets/icons/dance.png";
import trophy from "../assets/icons/trophy.png";
import balloon from "../assets/icons/balloon.png";
import brush from "../assets/icons/brush.png";
import cake from "../assets/icons/cake.png";
import party from "../assets/icons/party.png";

export const ServicesData = [
  {
    icon: dance,
    title: "Танцувални игри",
    desc: "Раздвижете всяко парти с танцови предизвикателства и музика! Нашите танцови игри са идеални за всички възрасти и обещават непрекъснато вълнуващо и забавно преживяване. ",
  },
  {
    icon: trophy,
    title: "Състезателни игри",
    desc: "Разпалете ентусиазма с разнообразен набор от състезателни игри. Игрите са предназначени да предизвикат радост и отборен дух.",
  },
  {
    icon: balloon,
    title: "Фигури с балони",
    desc: "Моите фигури от балони оживявяат въобръжението на малки и големи. Балоните могат да бъдат всичко от животни до фантастични герои.",
  },
  {
    icon: brush,
    title: "Рисунки върху личица",
    desc: "Перфектни за тематични партита. Преобразете гостите си с артистични рисунки, които радват както децата, така и възрастните. ",
  },
  {
    icon: cake,
    title: "Посрещане на тортата",
    desc: "Нека тортата ви бъде звездата на партито. Тя гарантира незабравим момент когато рожденикът взима първото парче.",
  },
  {
    icon: party,
    title: "Каквото ви хрумне",
    desc: "Независимо дали става въпрос за тематично парти, специално представление или друга дейност, аз съм отдадена в създването на уникално преживяване!",
  },
];

export const servicesDataForPageService = [
  {
    id: 2,
    title: "Парти с Елза – Ледено кралство на магия и приключения",
    introduction: "Готови ли сте да се потопите в снежния свят на Елза и Анна?",
    description: "Моето парти с Елза е вдъхновено от любимата анимация „Замръзналото кралство“ и ще пренесе децата в магическия свят на леденото кралство Арендел! Елза ще поведе малките приключенци в един вълшебен и снежен празник, изпълнен с игри и незабравими моменти.",
    party_includes: [
      "Ледено посрещане от Елза",
      "Приключенски игри",
      "Магически снежни танци",
      "Музикална програма",
      "Рисуване на лица (при възможност)",
      "Подаръчета"
    ],
    image: "/services/elza.jpg",
    why_choose_party: [
      "Перфектно за феновете на „Замръзналото кралство“, които обичат снежните приключения и магическите моменти.",
      "Разнообразни забавления и игри, подходящи за различни възрасти.",
      "Професионална анимация, приказни костюми и уникална атмосфера, които ще направят празника незабравим."
    ],
    call_to_action: "Готови ли сте за магията на снежното кралство? Свържете се с мен и резервирайте своето вълшебно парти с Елза още днес!"
  },
  {
    id: 3,
    title: "Парти с Барби – Блясък, стил и забавление!",
    introduction: "Добре дошли в света на Барби, където мечтите стават реалност!",
    description: "Моето парти с Барби е идеалният избор за всички малки принцеси и любители на модата, стила и блясъка! Потопете се в магическия свят на Барби с игри, забавления и много розови изненади.",
    party_includes: [
      "Грандиозно посрещане на Барби",
      "Моден подиум и ревю",
      "Игри и предизвикателства",
      "Танци и музика",
      "Рисуване на лица (при възможност)",
      "Подаръчета"
    ],
    image: "/services/barby.jpg",
    why_choose_party: [
      "Тематични активности, подходящи за всички, които обичат блясъка и модата.",
      "Създаване на стилна и приказна атмосфера, където всяко дете ще се почувства като истинска звезда.",
      "Професионална анимация и внимание към детайла, което гарантира уникално преживяване за рожденика и гостите."
    ],
    call_to_action: "Готови ли сте за бляскаво парти с Барби? Свържете се с мен и резервирайте своето незабравимо приключение още днес!"
  },
  {
    id: 4,
    title: "Парти с Калинката или Черния котарак – Героично приключение!",
    introduction: "Готови ли сте за смело приключение със супергероите Калинката и Черния котарак? ",
    description: "Моето парти с темата „Калинката и Черния котарак“ ще пренесе децата в света на Париж, където ще могат да се превъплътят в любимите си герои и да спасяват деня! Подходящо за всички, които обичат супергеройските предизвикателства и забавления.",
    party_includes: [
      "Среща със супергерой",
      "Героически мисии и игри",
      "Супергеройски тренировки (ако мястото го позволява)",
      "Музикална програма",
      "Рисуване на личица (при възможност)",
      "Подаръчета"
    ],
    image: "/services/kotka.jpg",
    why_choose_party: [
      "Тематично и вълнуващо приключение, подходящо за всички малки супергерои.",
      "Забавления и активности, които насърчават децата да бъдат смели и да работят в екип."
    ],
    call_to_action: "Готови ли сте за приключение със супергероите? Свържете се с мен и резервирайте своето незабравимо парти с Калинката или Черния котарак още днес!"
  },
  {
    id: 5,
    title: "Парти с Пипи Дългото чорапче – Весело приключение с най-силното момиче на света!",
    introduction: "Готови ли сте за незабравимо приключение с Пипи Дългото чорапче?",
    description: "Потопете се в пъстрия и забавен свят на Пипи – момичето с рижите плитки и голямото сърце! Това парти е идеалният избор за децата, които обичат пакостите, приключенията и смеха.",
    party_includes: [
      "Посрещане от самата Пипи",
      "Приключенски игри",
      "Рисуване на личица (при възможност)",
      "Музикална програма",
      "Подаръчета"
    ],
    image: "/services/pipi.jpg",
    why_choose_party: [
      "Перфектен избор за деца, които обичат смеха, приключенията и шантавите игри.",
      "Вълнуващи тематични игри и активности, подходящи за различни възрасти."
    ],
    call_to_action: "Готови ли сте за забавления с Пипи? Свържете се с мен и резервирайте своето шантаво и приключенско парти още днес!"
  },
  {
    id: 6,
    title: "Парти с Маша – Весели пакости и приключения!",
    introduction: "Добре дошли в света на Маша и Мечока – мястото, където винаги има смях и приключения!",
    description: "Пригответе се за парти, изпълнено с щури забавления, смях и безброй игри, вдъхновени от палавата Маша! Това празненство ще пренесе децата в гората, където те ще могат да се потопят в приказния свят на любимата анимация.",
    party_includes: [
      "Посрещане от Маша",
      "Игри и пакости с Маша",
      "Урок по танци",
      "Музикална програма",
      "Рисуване на личица (при възможност)",
      "Подаръчета"
    ],
    image: "/services/masha.jpg",
    why_choose_party: [
      "Идеално за деца, които обичат щурите игри, смеха и забавленията.",
      "Тематични игри и активности, вдъхновени от обичаната анимация."
    ],
    call_to_action: "Готови ли сте за весело приключение с Маша? Свържете се с мен и резервирайте своето щуро парти още днес!"
  },
  {
    id: 7,
    title: "Пиратско парти – Приключение за малките пирати!",
    introduction: "Хей, пирати! Готови ли сте за съкровища, загадки и морски приключения?",
    description: "Пригответе се за незабравимо пиратско парти, което ще пренесе децата в света на бурните морета, скритите съкровища и пиратските кораби! Подходящо за всички малки приключенци, които искат да се почувстват като истински пирати.",
    party_includes: [
      "Посрещане от пиратски капитан",
      "Търсене на съкровището",
      "Пиратски игри и изпитания",
      "Грим и татуировки",
      "Музикална програма",
      "Подаръчета"
    ],
    image: "/services/pirat.jpg",
    why_choose_party: [
      "Идеално за малки приключенци, които обичат мистериите и предизвикателствата.",
      "Богата програма с игри и активности, подходяща за различни възрасти."
    ],
    call_to_action: "Готови ли сте да вдигнете платната и да тръгнете на пиратско приключение? Свържете се с мен и резервирайте своето пиратско парти още днес!"
  },
  {
    id: 8,
    title: "Футболно парти – За малките шампиони!",
    introduction: "Подгответе се за голямо футболно приключение, изпълнено с емоции и забавления!",
    description: "Ако детето ви обича да рита топка и мечтае да стане истински шампион, футболното парти е идеалният избор! Това е парти, което ще потопи малките спортисти в света на футболните игри, състезания и забавления.",
    party_includes: [
      "Добре дошли на стадиона: Аниматор, облечен като футболист, ще посрещне децата",
      "Футболни игри и състезания",
      "Рисуване на личица при възможност",
      "Музика и танци",
      "Подаръчета"
    ],
    image: "/services/mach.jpg",
    why_choose_party: [
      "Подходящо за всички малки любители на футбола и спорта.",
      "Вълнуващи игри и състезания, които ще създадат незабравими спомени."
    ],
    call_to_action: "Готови ли сте да влезете в играта? Свържете се с мен и резервирайте своето футболно парти още днес!"
  },
  {
    id: 10,
    title: "Парти с Рапунцел – Приказка, изпълнена с магия и приключения!",
    introduction: "Готови ли сте да се потопите в приказния свят на Рапунцел и да изживеете невероятни приключения в кулата?",
    description: "Моето парти с Рапунцел ще пренесе децата в света на дългокосата принцеса, изпълнен с магия, смелост и мечти. Малчуганите ще станат част от нейната приказка, където ще откриват съкровища, ще се забавляват с творчески игри и ще почувстват магията на истинската принцеса.",
    party_includes: [
      "Кралско посрещане – Рапунцел ще приветства всички гости с топла усмивка.",
      "Игри и предизвикателства",
      "Приказен танц",
      "Песни от любимата приказка – музикална програма, която ще заплени всички.",
      "Рисуване на личица (при възможност)",
      "Подаръчета"
    ],
    image: "/services/rapuncel.jpg",
    why_choose_party: [
      "Подходящо за всички малки принцеси, които мечтаят да бъдат част от приказен свят.",
      "Забавления, които съчетават творчество, игри и магия.",
      "Вдъхновяваща атмосфера с много усмивки и незабравими моменти."
    ],
    call_to_action: "Готови ли сте да се качите в кулата и да се впуснете в магическо приключение? Свържете се с мен и резервирайте своето парти с Рапунцел още днес!"
  },
  {
    id: 11,
    title: "Парти с Mickey Mouse – Вълшебен свят на забавления и усмивки!",
    introduction: "Готови ли сте да се потопите в магическия свят на Mickey Mouse и неговите приятели?",
    description: "Готови ли сте да се потопите в магическия свят на Mickey Mouse и неговите приятели?",
    party_includes: [
      "Весело посрещане",
      "Игри и предизвикателства",
      "Музикална програма – песни и танци за най-малките фенове.",
      "Рисуване на личица – при възможност",
      "Малки подаръчета"
    ],
    image: null,
    why_choose_party: [
      "Подходящо за всички малки герои, които обичат приключенията и веселбата.",
      "Гарантирано забавление за децата и безброй усмивки.",
      "Уникална атмосфера, вдъхновена от един от най-обичаните герои в света."
    ],
    call_to_action: "Готови ли сте за приключение в клубната къща? Свържете се с мен и резервирайте своето парти с Mickey Mouse още днес!"
  },
  {
    id: 12,
    title: "Парти с Еднорог – Магия, цветове и вълшебни приключения!",
    introduction: "Готови ли сте да се потопите в приказния свят на еднорозите и да преживеете ден, изпълнен с чудеса и усмивки?",
    description: "Готови ли сте да се потопите в приказния свят на еднорозите и да преживеете ден, изпълнен с чудеса и усмивки?",
    party_includes: [
      "Приказно посрещане – за всяко дете в света на еднорозите.",
      "Магически игри и състезания – вдъхновени от фееричния свят на еднорозите.",
      "Рисуване на личица (при възможност).",
      "Музикална програма – с весели танци и песни, които ще радват всички.",
      "Малки подаръчета"
    ],
    image: null,
    why_choose_party: [
      "Идеално за малките любители на магия, цветове и вълшебства.",
      "Забавления, които съчетават творчество, игри и много блясък."
    ],
    call_to_action: "Готови ли сте да препуснете в света на еднорозите? Свържете се с мен и резервирайте своето парти с Еднорог още днес!"
  },
  {
    id: 13,
    title: "Парти с Жената Котка – Тайни мисии и супергеройски приключения!",
    introduction: "Готови ли сте да се впуснете в света на загадъчната Жената Котка и да изживеете невероятни приключения?",
    description: "Моето парти с Жената Котка ще пренесе децата в свят на мистерии, смелост и супергеройски мисии. Малчуганите ще се превърнат в малки герои, готови за забавления, игри и тайни предизвикателства в компанията на загадъчната супергероиня.",
    party_includes: [
      "Супергеройско посрещане – Жената Котка приветства всички гости със стил и загадъчност.",
      "Игри и предизвикателства",
      "Танци с Жената Котка – динамична музикална програма, изпълнена със забавления.",
      "Рисуване на личица – за всички, които искат да се преобразят като супергерои (при възможност).",
      "Малки подаръчета"
    ],
    image: null,
    why_choose_party: [
      "Подходящо за малчугани, които обичат приключенията, супергероите и екшъна.",
      "Игри и забавления, които ще държат децата ангажирани и усмихнати през цялото време."
    ],
    call_to_action: "Готови ли сте за приключение със супергеройски размах? Свържете се с мен и резервирайте своето парти с Жената Котка още днес!"
  },
  {
    id: 9,
    title: "Парти със Супер Марио – Приключение в Марио-стил!",
    introduction: "Готови ли сте да се потопите в света на Супер Марио и да спасявате принцеси, събирате монети и преминавате през вълнуващи нива?",
    description: "Моето парти със Супер Марио ще пренесе децата в емблематичния свят на Марио и неговите приятели! Те ще могат да се почувстват като истински герои, готови за приключения в кралството на гъбите, скритите съкровища и невероятните предизвикателства.",
    party_includes: [
      "Посрещане в Марио-стил",
      "Приключенски игри и нива",
      "Гъбени състезания",
      "Събиране на монети",
      "Музикална програма",
      "Подаръчета"
    ],
    image: null,
    why_choose_party: [
      "Идеално за деца, които обичат видеоигрите и света на Супер Марио.",
      "Вълнуващи игри и активности, вдъхновени от любимата игра, които развлекат и забавават."
    ],
    call_to_action: "Готови ли сте да преминете през всички нива и да спасите принцесата? Свържете се с мен и резервирайте своето парти със Супер Марио още днес!"
  },
  {
    id: 1,
    title: "Парти с Бел – Приказно приключение в замъка на Звяра",
    introduction: "Поканете малките принцеси и принцове на магическо пътешествие в света на Бел и Звяра!",
    description: "Вашето дете ще има възможността да се потопи в класическа приказка, изпълнена с магия, смелост и любов. Моето парти с Бел от „Красавицата и Звяра“ ще превърне всеки рожден ден в незабравимо празненство, вдъхновено от историята за доброто сърце и приключенията на любимата принцеса.",
    party_includes: [
      "Кралска поява на Бел",
      "Игри от вълшебния замък",
      "Музикална програма",
      "Рисуване на лица (при възможност)",
      "Подаръчета"
    ],
    image: null,
    why_choose_party: [
      "Една класическа и обичана история, която ще накара децата да мечтаят и да се забавляват.",
      "Вълнуващи тематични игри, адаптирани за различни възрасти.",
      "Професионална анимация"
    ],
    call_to_action: "Готови ли сте за кралско приключение с Бел? Свържете се с мен и резервирайте своята магическа приказка още днес!"
  },
];