import React from "react";
import Icons from "./Icons";
import { Link } from "react-router-dom";

const links = [
    {
        href: "/",
        text: "НАЧАЛО",
    },
    {
        href: "/about",
        text: "ЗА МЕН",
    },
    {
        href: "/services",
        text: "УСЛУГИ",
    },
    {
        href: "/reviews",
        text: "ОТЗИВИ",
    },
    {
        href: "/gallery",
        text: "ГАЛЕРИЯ",
    },
    {
        href: "/contact",
        text: "KОНТАКТИ",
    },
];

const Footer = () => {
    const date = new Date();
    return (
        <footer className="border-t-primary/30 border-t text-primary">
            <div className="mx-6 py-10 text-center lg:pl-20 md:text-left">
                <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    <div className="">
                        <h1>Викси Трикси</h1>
                        <p>
                            С усмивка и вдъхновение, създавам вълшебство и радост!
                            Аниматорът в мен винаги търси нови начини да направи вашето събитие незабравимо.
                            Заедно да създадем спомени, които ще се превърнат в приказни моменти.
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        {links.map((data, i) => (
                            <div
                                key={`nav_link${i}`}
                                className="hover:text-primaryDark text-primary transition-colors text-p text-center md:text-left"
                            >
                                <Link to={data.href}>{data.text}</Link>
                            </div>
                        ))}
                    </div>
                    <div>
                        <h6
                            className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                            АДРЕС: ВАРНА И ОКОЛНОСТТА
                            <br></br>
                            КОНТАКТИ
                        </h6>
                        <p className="mb-4 flex items-center justify-center md:justify-start">
                            <a href="https://www.facebook.com/profile.php?id=100091438608613" className="text-xl mx-2 flex items-center underline">
                                <Icons.Facebook className="mr-2" /> Детска забава с Викси-Трикси
                            </a>
                        </p>
                        <p className="mb-4 flex items-center justify-center md:justify-start">
                            <a href="tel:+359899019997" className="text-xl mx-2 flex items-center underline">
                                <Icons.Phone className="mr-2" /> 089 901 9997
                            </a>
                        </p>
                        <p className="mb-4 flex items-center justify-center md:justify-start">
                            <a href="mailto:viktoriya.2000@abv.bg" className="text-xl mx-2 flex items-center  underline">
                                <Icons.Mail className="mr-2" /> viktoriya.2000@abv.bg
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div className="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
                <p className="text-sm text-gray-500">&copy; {date.getFullYear()} Викси Трикси. Всички права запазени.</p>
            </div>
        </footer>

    );
}

export default Footer;
