import React from 'react';

export function ServiceModal({ isOpen, onClose, service }) {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                    <h2 className="text-3xl text-primary font-bold mb-4">{service.title}</h2>
                    <p className="text-gray-700 mb-6">{service.description}</p>
                    <div className="mb-6">
                        <h3 className="text-xl text-primary font-semibold mb-3">Включва:</h3>
                        <ul className="list-disc list-inside space-y-2">
                            {service.party_includes.map((item, index) => (
                                <li key={index} className="text-gray-600">{item}</li>
                            ))}
                        </ul>
                    </div>

                    <h3 className="text-xl text-primary font-semibold mb-3">Защо да изберете това парти</h3>
                    <p className="text-gray-700 mb-6">{service.why_choose_party}</p>
                    <div className="flex justify-end">
                        <button
                            onClick={onClose}
                            className="px-6 py-3 bg-primary text-white rounded-lg hover:bg-pink-600 transition-colors"
                        >
                            Затвори
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}