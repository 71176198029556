import React from 'react';


export function ServiceCard({ title, introduction, image, onOpen }) {
    return (
        <div
            onClick={onOpen}
            className="bg-white rounded-xl shadow-lg overflow-hidden transition-transform hover:scale-105 flex flex-col"
            style={{ minHeight: '450px' }} // Ensures all cards are of uniform height
        >
            <div className="w-full">
                {image ? (
                    <img
                        loading="lazy"
                        src={image}
                        alt="Description"
                        className="object-cover w-full h-[300px]" // Consistent height for images
                    />
                ) : (
                    <div className="w-full h-[300px] bg-gray-200"></div> // Placeholder for missing images
                )}
            </div>
            <div className="flex flex-col p-4 flex-grow">
                <div className="w-full whitespace-nowrap">
                    <div className="leading-6 text-primary">
                        {title}
                    </div>
                </div>
                <div className="mt-4 text-sm tracking-wide leading-5 text-zinc-700">
                    {introduction}
                </div>
                <div className="flex gap-2 items-start mt-4 w-full">
                    <button
                        className="px-8 py-2 bg-primary text-white rounded-lg hover:bg-pink-600 transition-colors"
                    >
                        Повече информация
                    </button>
                </div>
            </div>
        </div>
    );
}
